import Router from "./components/Router"
import "./library/library.scss"
import "./components/wrapper/wrapper.scss"

function App() {
  return (
    <Router>
    </Router>
  )
}

export default App
